<script setup lang="ts">
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppAlert from "@/Components/Shared/alert/AppAlert.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";
import {router, usePage} from "@inertiajs/vue3";
import {computed} from "vue";

const handleSubmit = () => {
    router.post("/account/close-2fa-notification");
};

const seen = computed(() => usePage().props?.tcg?.seen_2fa);
</script>

<template>
    <AppAlert
        v-if="!seen"
        size="small"
        variant="warning borderless"
        icon-size="xsmall"
        closeable
    >
        Je hebt nog geen two-factor authenticatie ingesteld. Dit kun je
        instellen in je

        <AppLink
            href="/account/security"
            color="currentColor"
            variant="underlined"
        >account</AppLink>.

        <template #close>
            <form @submit.prevent="handleSubmit">
                <AppButton
                    type="submit"
                    icon="cross"
                    icon-size="small"
                    variant="clean"
                />
            </form>
        </template>
    </AppAlert>
</template>
